import datasetSchoolYear from '@/datasets/schoolYear'

export default {
  browse:{
    table:{
      headers: [
        // {
        //   text: 'No.',
        //   value: 'index',
        //   sortable: false,
        //   filterable: false,
        // },
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Created',
          value: 'dateCreated',
          filterable: false,
        },
        // {
        //   text: 'Year',
        //   value: 'year',
        //   filterable: false,
        // },
        {
          text: 'School',
          value: 'School',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'School Year',
          value: 'schoolYear',
        },
        {
          text: 'Code',
          value: 'code',
        },
        {
          text: 'Enable Walk-in',
          value: 'enableWalkin',
        },
        {
          text: 'Student',
          value: 'countStudent',
        },
        {
          text: 'Status',
          value: 'isActive',
          filterable: false,
        },
      ],
    },
  },
  read:{
    tabs:[
      // {
      //   key:'Student',
      //   label:'students',
      // },
      // {
      //   key:'Teacher',
      //   label:'teachers',
      // },
      // {
      //   key:'Event',
      //   label:'events',
      // },
      {
        key:'Info',
        label:'info',
      },
    ],
    table:{
      headers: [
        {
          text: 'School',
          value: 'School',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Year',
          value: 'year',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'School Year',
          value: 'schoolYear',
        },
        {
          text: 'Code',
          value: 'code',
        },
        {
          text: 'Status',
          value: 'isActive',
          filterable: false,
        },
        {
          text: 'Created',
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text: 'Last Updated',
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:{
    fields:[
      {
        type:'model',
        key:'schoolId',
        name:'School',
        model:'school',
        optionText:'name',
        optionValue:'id',
        defaultValue:1,
      },
      {
        type:'select',
        key:'schoolYear',
        name:'School Year',
        options:datasetSchoolYear,
        optionText:'name',
        optionValue:'id',
        defaultValue:1,
      },
      {
        type:'string',
        key:'name',
        name:'Name',
      },
    ],
    rules:{
      schoolId: [v => !!v || 'School is required'],
      name: [v => !!v || 'Name is required'],
    },
  },
  edit:true,
  delete:true,
}