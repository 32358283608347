import Admin from '@/models/bread/Admin/classroom'
import Moderator from '@/models/bread/Moderator/classroom'
import Teacher from '@/models/bread/Teacher/classroom'

export default {
  key:'Classroom',
  name:{
    singular:'Classroom',
    plural:'Classrooms',
  },
  parents:[
    'Account',
  ],
  Admin:Admin,
  Moderator:Moderator,
  Teacher:Teacher,
}